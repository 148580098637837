import React, {useMemo,  useState} from "react"
import MUIDataTable from "mui-datatables"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Button, Box, Typography } from "@mui/material"
import { Wifi } from "@mui/icons-material"

export default function ({
  title,
  data,
  setOpenModal,
  handleCheck,
  isWifi = false,
  selectableRows='selectableRows',
  enableFilter= false,
  enableFilterType= "textField",
  useCustomSelection = false,
}) {
  const [selected, setSelected] = useState([])


    const ndata = useMemo(() => {
        console.log("Data updated:", data); // 添加日志
        return isWifi ? data.map(obj => ({ ...obj, wifiState: obj.wifiState })) : data;
    }, [data, isWifi]);



  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: "#28aeb1",
        },
        info: {
          light: "#cccccc",
          main: "#666666",
        },
        error: {
          light: "#db807c",
          main: "#CC4A44",
        },
      },
      typography: {
        fontFamily: "'Noto Sans JP', sans-serif",
        h2: {
          fontSize: "22px",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "16px",
        },
        h5: {
          fontSize: "14px",
        },
        button: {
          textTransform: "none",
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: "0.02rem",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {},
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            // backgroundColor: 'yellow',
          },
        },
      },
    })
    const columns = [
        {
            name: "deviceName",
            label: "デバイス名",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const rowData = ndata[dataIndex];
                    if (isWifi) {
                        return (
                            <Box sx={{ display: "flex" }}>
                                <Typography sx={{ mr: "10px" }}>
                                    {rowData.deviceName}
                                </Typography>
                                <Wifi
                                    style={{
                                        color: rowData.wifiState ? "#28aeb1" : "#999",
                                    }}
                                />
                            </Box>
                        )
                    } else {
                        return <Typography>{rowData.deviceName}</Typography>
                    }
                },
            },
        },
    ];

  return (
    <Box>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<Typography variant="h2">{title}</Typography>}
          columns={columns}
          data={ndata}
          options={{
            setRowProps: (row, dataIndex, rowIndex) => {
              return {
                key: row.deviceUUID || dataIndex,  // 假设 deviceUUID 是唯一的
              };
            },
            isRowSelectable: (dataIndex, selectedRows) => {
              // 在全體卡片才要有認證機器的WiFi顯示
              if (isWifi) {
                const row = ndata[dataIndex]
                return row.wifiState
              } else {
                return true
              }
            },

              count: ndata.length,
              onTableChange: (action, tableState) => {
                  console.log("数据变化了",action, tableState);

              },
              responsive: "standard",
              filter: enableFilter,
              filterType: enableFilterType,
            search: false,
            print: false,
             // serverSide: true,
            download: false,
            viewColumns: false,
            customToolbar: null,
            pagination: true,
            rowsPerPage: 10,
            rowsPerPageOptions: [5,8,10],
            elevation: 0,
            selectableRows:selectableRows,

            // onRowSelectionChange: (currentSelect, allSelected) => {
            //   setSelected(allSelected?.map(({ index }) => ndata[index]))
            // },
            onRowSelectionChange: (currentRowsSelected, allSelected, rowsSelected) => {
              if (useCustomSelection) {
                // 客製化
                setSelected(rowsSelected.map(index => ndata[index]))
              } else {
                // 預設
                setSelected(allSelected?.map(({ index }) => ndata[index]))
              }
            },


            setTableProps: () => {
              return {
                size: "small",
              }
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: "10px",
          }}
        >
          <Button
            size="small"
            sx={{ mr: "10px" }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            キャンセル
          </Button>
          <Button
            disableElevation
            size="small"
            variant="outlined"
            onClick={() => {
              handleCheck(selected)
            }}
          >
            確認
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  )
}
