import {Box, Button, IconButton, TextField, Typography} from "@mui/material"
import Cfptb from "../../components/cfp/cfptb"
import {cfpColumns} from "../../components/utils/cfpcolumns"
import React, {useContext, useEffect, useState} from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import Layout from "../../layouts"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CheckTable from "../../components/attendance/checkTable"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import CfpSimpleTable from "../../components/cfp/cfpsimpletable"
import {navigate} from "gatsby"
import {CfpSearch} from "../../components/cfp/cfpSearch"

export default function DeviceGroup({location}) {
    const [data, setData] = useState([])
    const [openHead, setopenHead] = useState(false)
    const [name, setName] = useState("")
    const [items, setItems] = useState([])
    const [deviceItems, setDeviceItems] = useState([])
    const [isGroup, setIsGroup] = useState(false)
    const {setCustomModalOpen, gCfp, gIot, setModalContent, gGroupDevice, gMemberDevice} =
        useContext(GlobalStateContext)
    useEffect(() => {
        console.log("gGroupDevice.list", gGroupDevice.list)
        if (gGroupDevice.list.length > 0) {
            let gids = gGroupDevice.list.map(item => item.gid)
            gGroupDevice.getMemberGroup({
                op: 'getd',
                list: gids
            }, (d) => {
                let nresult = []

                for (let i = 0; i < gGroupDevice.list.length; i++) {
                    let members = []
                    if (d[i].length > 0) {
                        members = d[i].map(item => {

                            return {...item, memberGName: gMemberDevice.findMemberGroupName(item.mid)}
                        })
                    }
                    nresult.push({
                        ...gGroupDevice.list[i], members: members
                    })
                }
                console.log("gGroupDevice.list", nresult)
                setData(nresult)
            })

        }


    }, [gGroupDevice.list])
    useEffect(() => {
        if (name && items.length > 0) {
            setIsGroup(true)
        } else {
            setIsGroup(false)
        }
        setDeviceItems(gCfp.cfpSsmDevices.filter(item => !items.some(it => it.deviceUUID === item.deviceUUID)))
    }, [name, items])

    const findSearchList = (items, key) => {
        return items.filter((item) => item.name.includes(key))
    }
    return (
        <>
            <Layout location={location}>
                <Box
                    sx={{display: "flex", flexDirection: "column", marginTop: "8px"}}
                >
                    <CfpSearch
                        callSearch={(e) => {
                            if (e) {
                                setData(findSearchList(gGroupDevice.list, e))
                            } else {
                                setData(gGroupDevice.list)
                            }
                        }}
                    />
                    {openHead && (
                        <Box
                            sx={{
                                marginLeft: "20px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box sx={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                                <Typography sx={{fontSize: "22px", fontWeight: "bold"}}>
                                    新規グループを追加
                                </Typography>
                                <IconButton
                                    sx={{marginRight: "10px"}}
                                    onClick={() => {
                                        setopenHead(false)
                                    }}
                                >
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </Box>
                            <TextField
                                size="small"
                                id="outlined-basic"
                                label="グループ名"
                                variant="outlined"
                                value={name}
                                sx={{
                                    width: "341px",
                                    marginTop: "10px",
                                    borderRadius: "6px",
                                }}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    margin: "10px 0px",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    component="p"
                                    style={{
                                        fontFamily: "'Noto Sans JP', sans-serif",
                                        fontSize: "18px",
                                        fontWeight: 400,
                                        lineHeight: "27px",
                                        letterSpacing: "0.06em",
                                        textAlign: "left",
                                    }}
                                >
                                    {`デバイス(${items.length})`}
                                </Typography>
                                <IconButton
                                    sx={{marginTop: "4px"}}
                                    onClick={() => {
                                        setCustomModalOpen(true)
                                        setModalContent(
                                            <CheckTable
                                                title={"デバイスを選択"}
                                                data={deviceItems} //資料
                                                selectableRows={"multiple"}
                                                enableFilter={true}
                                                useCustomSelection={true}
                                                setOpenModal={setCustomModalOpen} //開關Modal的屬性
                                                handleCheck={(value) => {
                                                    setCustomModalOpen(false)
                                                    setItems((prevState) => [...prevState, ...value])
                                                }} //勾選項目後要做的處理function
                                                location={location}
                                                isWifi={false}
                                            />
                                        )
                                    }}
                                >
                                    <AddCircleIcon style={{color: "#28AEB1"}}/>
                                </IconButton>
                            </Box>
                            <CfpSimpleTable
                                items={items}
                                btnDel={(item) => {
                                    setItems((prevState) =>
                                        prevState.filter(
                                            (obj) => obj.deviceUUID !== item.deviceUUID
                                        )
                                    )
                                }}
                            />
                            <Button
                                size="small"
                                variant="outlined"
                                disableElevation
                                sx={{
                                    width: "64px",
                                    borderRadius: "6px",
                                    color: "#28aeb1",
                                    marginBottom: "30px",
                                }}
                                disabled={!isGroup}
                                onClick={() => {
                                    let ids = items.map((item) => item.deviceUUID)

                                    gGroupDevice.addGroupDevice(name, ids, () => {
                                        setName("")
                                        setItems([])
                                    })
                                }}
                            >
                                登録
                            </Button>
                        </Box>
                    )}
                    <Cfptb
                        callAdd={() => {
                            setopenHead(true)
                        }}
                        isAdd={!openHead}
                        data={data}
                        isBind={false}
                        isBack={false}
                        path={"/devices/groupdetails"}
                        text={`ドアグループ一覧`}
                        columns={cfpColumns.deviceGroup({
                            clickCall: (list, v) => {
                                for (let i = 0; i < list.length; i++) {
                                    let find = gCfp.cfpSsmDevices.find(item => item.deviceUUID === list[i])

                                    if (find) {
                                        gIot.changeSwitch(find.deviceUUID, find.secretKey, v)
                                    }
                                    // await
                                }


                            }
                        })}
                        callDelData={(items) => {
                            let ids = items.map((item) => ({gid: item.gid}))
                            gGroupDevice.delsGroupDevice(ids, (objs) => {
                                setData(prevState => prevState.filter(item => !objs.some(obj => obj.gid === item.gid)));


                            })


                            //
                        }}
                        isKey={true}
                        btnCallKey={(items) => {

                            console.log("items", items)
                            if (items.length > 0) {

                                navigate("/devices/groupkey", {state: {data: items}})
                            }

                        }}
                    />
                </Box>
            </Layout>
        </>
    )
}
